const initBlock = async function( block ) {
	const body = $( "body" );
	const menuToggler = block.find( "[data-menu-toggler]" );
	const menu = block.find( "[data-menu]" );
	const mediaQuery = matchMedia( "(min-width: 1200px)" );

	class MobileMenu {
		constructor() {
			this.$html = $( "html" );
			this.$body = $( "body" );
			this.$header = $( ".section-header" );
			this.init();
		}

		init() {
			if ( !this.$header.length ) {
				return;
			}

			this.$menu = this.$header.find( ".section-header__mobile-nav" );
			this.$menu.find( ".dropdown-arrow" ).closest( "li" ).removeClass( "menu-active" );
			this.$menu.find( ".sub-menu" ).slideUp();

			this.menuToggle();
		}


		menuToggle() {
			this.$menu.find( ".dropdown-arrow" ).on( "click", e => {
				e.preventDefault();
				const $this = $( e.currentTarget );
				const $parentMenuItem = $this.closest( "li" );
				const $subMenu = $parentMenuItem.find( ".sub-menu" );
				$subMenu.slideToggle();
				$parentMenuItem.toggleClass( "menu-active" );
			} );
		}
	}

	new MobileMenu();

	mediaQuery.addEventListener( "change", function( event ) {
		if ( event.matches ) {
			hideMenu();
		} else {
			menu.find( ".dropdown-arrow" ).closest( "li" ).removeClass( "menu-active" );
			menu.find( ".sub-menu" ).slideUp();
		}
	} );

	menuToggler.on( "click", function() {
		if ( menu.hasClass( "active" ) ) {
			hideMenu();
		} else {
			showMenu();
		}
	} );

	function hideMenu() {
		body.removeClass( "scroll-locked" );
		menuToggler.removeClass( "active" );
		menu.removeClass( "active" );
	}

	function showMenu() {
		body.addClass( "scroll-locked" );
		menuToggler.addClass( "active" );
		menu.addClass( "active" );
	}
};

themeUtils.loadBlock( initBlock, "header", ".section-header" );
